import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockContent from '../block-content'
// import Container from '../container/container'
import RoleList from '../role-list/role-list'
import BlogPostTags from '../blog-tags/blog-post-tags'

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

function BlogPost (props) {
  const { _rawBody, authors, _rawCategories, title, mainImage, publishedAt } = props
  return (
    <>
    <div className={'page-header page-header-image--background'}>
      {mainImage && mainImage.asset && (
        <Row
          style={{ 
            backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(mainImage.asset) ? mainImage.asset : imageUrlFor(buildImageObj(mainImage)).url()})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100vw',
            textAlign: 'center',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Container
            style={{
              maxHeight: 300
            }}>
              <h1 class="title">{title}</h1>
              {
                props.authors && (
                  <div className="author">
                    <div style={{
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      {
                        props.authors[0].person.image &&
                        (<img src={imageUrlFor(buildImageObj(props.authors[0].person.image)).url()} alt={props.authors[0].person.name} class="avatar img-raised" />)
                      }
											<h4 class="category">
												{differenceInDays(new Date(publishedAt), new Date()) > 3
												? distanceInWords(new Date(publishedAt), new Date())
												: format(new Date(publishedAt), 'MMMM Do YYYY')}
											</h4>
                      {
                        props.authors[0].person.name && (
                        <h4 className={'description'}>{props.authors[0].person.name}</h4>
                        )
											}
                    </div>
                  </div>
                )
              }
          </Container>
        </Row>
      )}
      </div>
      <Container style={{paddingBottom: 40, paddingTop: 40}}>
				<Row>
					<Col md={8} className={'mr-auto ml-auto'}>
						<Row>
							<Col md={12} style={{paddingTop: 30}}>
								{_rawBody && <BlockContent blocks={_rawBody} />}
							</Col>
						</Row>
						<Row>
							<Col md={12} style={{paddingTop: 30}}>
								{_rawCategories && (
									<BlogPostTags categories={_rawCategories} label/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
    </Container>
    </>
  )
}

export default BlogPost
